import { reportApi } from "@/api/report.js";
export default {
  name: "Report",
  components: {},
  data: () => ({
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        id: "report-chart",
        fontFamily: "urw-din",
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
      },
      legend: {
        position: "top",
        fontSize: "15px",
      },
    },
    series: [
      {
        name: "Tiền xăng/dầu",
        data: [30, 40, 45, 50, 49, 60, 70, 91],
      },
      {
        name: "Tiền xe",
        data: [10, 23, 45, 23, 49, 6, 5, 7],
      },
    ],
    cars: JSON.parse(localStorage.getItem("userData")).cars,
    dataReport: null,
    time: 0,
    carId: JSON.parse(localStorage.getItem("userData")).cars[0].id,
    from: null,
    to: null,
  }),
  watch: {
    time(val) {
      this.from = this.dayjs().subtract(val, "day").format("YYYY-MM-DD");
      this.to = this.dayjs().format("YYYY-MM-DD");
      this.getReportAdvanced();
    },
    carId() {
      this.getReportAdvanced();
    },
  },
  async created() {
    this.from = this.dayjs().subtract(0, "day").format("YYYY-MM-DD");
    this.to = this.dayjs().format("YYYY-MM-DD");
    await this.getReportAdvanced();
  },
  methods: {
    async getReportAdvanced() {
      let body = {
        carId: this.carId,
        from: this.from,
        to: this.to,
      };
      await reportApi.getReportAdvanced(body).then((res) => {
        this.dataReport = res;
      });
    },
  },
};
